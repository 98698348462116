import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Elements } from "@stripe/react-stripe-js";
import ClassPaymentForm from '../PaymentForms/ClassPaymentForm'
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from '../../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/close.png'
import stripeLogo from '../../../Assets/stripe.png'
import card from '../../../Assets/money.png'

export default function ModalBooking({event, onClose, getBookings, bookings }) {

    let { currentUser } = useAuth()
    const [saving, setSaving] = useState(false)
    const [ownPasses, setOwnPasses] = useState()
    const [teacher, setTeacher] = useState()
    const [numberOfBookings, setNumberOfBookings] = useState(false)
    const [selectPass, setSelectPass] = useState(false)
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    useEffect(() => {
        getBookings()
        getOwnPasses()
        getProfile()
    }, [])

    useEffect(() => {
        const bookingsFiltered = bookings.filter((booking) => booking.eventId === event._id)
        setNumberOfBookings(bookingsFiltered.length)
    }, [bookings])

    const handleContainerClick = (e) => {
        e.stopPropagation() // Prevents click event from bubbling up to the modal div
    }

    const getOwnPasses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/passes/get-applicable-passes`, {studentId: currentUser.uid, teacherId: event.teacherId})
        .then((res) => {
            setOwnPasses(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getProfile = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/get-teacher-by-id`, {_id: event.teacherId})
        .then((res) => {
            setTeacher(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const closeModal = () => {
        setShowPaymentForm(false)
        onClose()
    };

    const handlePaymentSuccess = (paymentData) => {
        console.log('Payment Successful: ', paymentData);
        bookWithCard(paymentData.subscriptionScheduleId)
        setShowPaymentForm(false)
    };

    const bookWithCard = (subscriptionId) => {
        setSaving(true)
        console.log("Book with card")

        let payload = {
            date: event.date,
            start: event.start,
            end: event.end,
            title: event.className,
            studentId: currentUser.uid,
            studentName: currentUser.displayName,
            teacherId: event.teacherId,
            teacherName: `${teacher.firstName} ${teacher.lastName}`,
            eventId: event._id,
            className: event.className,
            classId: event.classId,
            venueName: event.venueName,
            price: event.price,
            paymentType: "Card",
            paid: true,
            subscriptionScheduleId: subscriptionId,
            passId: "",
            googleMaps: event.googleMaps,
            email: currentUser.email
        }

        axios.post(`${process.env.REACT_APP_API_URL}/bookings/create-new-booking`, payload)
        .then((res) => {
            if (res.data === 'Booked') {
                getBookings()
            }
            setSaving(false)
        })
        .catch((error) => {
            console.log(error)
            toast.error("Booking failure. Please try again or contact support.")
            setSaving(false)
        })
    }

    const bookWithCash = () => {
        setSaving(true)
        console.log("Book with cash")

        let payload = {
            date: event.date,
            start: event.start,
            end: event.end,
            title: event.className,
            studentId: currentUser.uid,
            studentName: currentUser.displayName,
            teacherId: event.teacherId,
            teacherName: `${teacher.firstName} ${teacher.lastName}`,
            eventId: event._id,
            className: event.className,
            classId: event.classId,
            venueName: event.venueName,
            price: event.price,
            paymentType: "Cash",
            paid: false,
            subscriptionScheduleId: "",
            passId: "",
            googleMaps: event.googleMaps
        }

        axios.post(`${process.env.REACT_APP_API_URL}/bookings/create-new-booking`, payload)
        .then((res) => {
            if (res.data === 'Booked') {
                getBookings()
            }
            setSaving(false)
        })
        .catch((error) => {
            console.log(error)
            toast.error("Booking failure. Please try again or contact support.")
            setSaving(false)
        })
    }

    const bookWithPass = (passId) => {
        if (!passId) {
            toast.error("No pass selected")
            return
        }
        setSaving(true)
        console.log("Book with pass")

        let payload = {
            date: event.date,
            start: event.start,
            end: event.end,
            title: event.className,
            studentId: currentUser.uid,
            studentName: currentUser.displayName,
            teacherId: event.teacherId,
            teacherName: `${teacher.firstName} ${teacher.lastName}`,
            eventId: event._id,
            className: event.className,
            classId: event.classId,
            venueName: event.venueName,
            price: event.price,
            paymentType: "Pass",
            paid: true,
            subscriptionScheduleId: "",
            passId,
            googleMaps: event.googleMaps
        }

        axios.post(`${process.env.REACT_APP_API_URL}/bookings/create-new-booking`, payload)
        .then((res) => {
            if (res.data === 'Booked') {
                getBookings()
            }
            setSaving(false)
            setSelectPass(false)
        })
        .catch((error) => {
            console.log(error)
            toast.error("Booking failure. Please try again or contact support.")
            setSaving(false)
            setSelectPass(false)
        })
    }

    const selectOwnPass = () => {
        if (ownPasses.length > 1) {
            setSelectPass(!selectPass)
        } else if (ownPasses.length === 1) {
            // called from pass button which is only shown if there is at least 1 ownPass
            // however, to prevent any possibility of booking with no pass, include the
            // condition
            bookWithPass(ownPasses[0]._id)
        }
    }

    return (
        <div className='modal' onClick={closeModal}>
            <div className='modal-container' onClick={handleContainerClick}>
                <img className='hover close-modal-btn' src={close} alt='Close Om Yoga box' onClick={closeModal}/>
                <h2 className='modal-header'>Class Booking</h2>
                <div className='divider' />

                <div className='modal-event-container'>
                    <div className='class-date'>
                        <div className='class-month'>
                            <p className='month-title'>{moment(event.start).format('MMMM')}</p>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: 10}}>
                            <p className='month-date'>{moment(event.start).format('DD')}</p>
                            <p style={{color: '#a28f89', fontWeight: 'bold', margin: 0}}>{moment(event.start).format('dddd')}</p>
                        </div>
                    </div>

                    <div className='modal-description'>
                        <p style={{fontSize: 24}}>{event.className}</p>
                        <p style={{color: '#717171'}}>{event.venueName}</p>
                        <p style={{color: '#717171'}}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p>
                    </div>
                </div>

                <div className='divider' />

                <div>
                    <p style={{ fontSize: 18, marginLeft: 5 }}>
                        {numberOfBookings === 0
                            ? "You currently have no bookings for this class."
                            : `You have ${numberOfBookings} booking${numberOfBookings > 1 ? 's' : ''} for this class.`}
                    </p>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <p style={{fontSize: 16, marginLeft: 5}}>Book this class: £{event.price}</p>

                        {showPaymentForm &&
                            <img className="stripe-attribution" src={stripeLogo} alt="Stripe logo"/>
                        }
                    </div>

                    {!showPaymentForm &&
                    <div id='pay-btns'>
                    {(!!teacher?.connectData?.paymentsPermitted) && (
                        <button
                            className='om-btn'
                            onClick={() => setShowPaymentForm(!showPaymentForm)}
                            disabled={saving || selectPass}
                            style={{
                                marginTop: '5px',
                                marginRight: '5px',
                                color: (saving || selectPass) ? '#ccc' : showPaymentForm && '#888',
                                cursor: (saving || selectPass) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <img src={card} style={{height: 30, width: 30}}/>
                            {(saving) ? 'Booking...' : 'Pay with card'}
                        </button>
                    )}

                    {(ownPasses?.length > 0) && (
                        <button
                            className='om-btn'
                            onClick={selectOwnPass}
                            disabled={saving || showPaymentForm}
                            style={{
                                marginTop: '5px',
                                marginRight: '5px',
                                color: (saving || showPaymentForm) ? '#ccc' : selectPass && '#888',
                                cursor: (saving || showPaymentForm) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            {(saving) ? 'Booking...' : (selectPass) ? 'Cancel pay with pass' : 'Pay with pass'}
                        </button>
                    )}

                    <button
                        className='om-btn'
                        onClick={bookWithCash}
                        disabled={saving}
                        style={{
                            marginTop: '5px',
                            color: (saving || showPaymentForm || selectPass) && '#ccc',
                            cursor: (saving || showPaymentForm || selectPass) ? 'not-allowed' : 'pointer'
                        }}
                    >
                        {(saving) ? 'Booking...' : 'Pay at venue'}
                    </button>
                    </div>
                    }

                    {(showPaymentForm) && (
                        <Elements stripe = {stripePromise}>
                            <ClassPaymentForm
                                onSuccess={handlePaymentSuccess}
                                student={currentUser}
                                event={event}
                                connectedAccountId={teacher.connectData?.accountId}
                                setShowPaymentForm={setShowPaymentForm}
                                />
                        </Elements>
                    )}

                    {(selectPass) && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                            <select onChange={(e) => bookWithPass(e.target.value)} defaultValue="">
                                <option value="" disabled>Select pass to complete booking</option>
                                {ownPasses.map(pass => (
                                    <option key={pass._id} value={pass._id}>{pass.passName}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

