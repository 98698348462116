import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/close.png'

export default function ModalCancelling({event, onClose, getBookings, bookings }) {

    const [cancelling, setCancelling] = useState(false)

    useEffect(() => {
        getBookings()
    }, [])

    const handleContainerClick = (e) => {
        e.stopPropagation() // Prevents click event from bubbling up to the modal div
    }

    const closeModal = () => {
        onClose()
    }

    const handleCancellation = (booking) => {
        setCancelling(true)

        axios.post(`${process.env.REACT_APP_API_URL}/bookings/cancel-booking`, booking)
        .then((res) => {
            toast.success(res.data)
            getBookings()
            setCancelling(false)
        })
        .catch((error) => {
            console.log(error)
            setCancelling(false)
        })
    }

    return (
        <div className='modal' onClick={closeModal}>
            <div className='modal-container' onClick={handleContainerClick}>
                <img className='hover close-modal-btn' src={close} alt='Close Om Yoga box' onClick={closeModal}/>
                <h2 className='modal-header'>Class Cancellation</h2>
                <div className='divider' />

                <div className='modal-event-container'>
                    <div className='class-date'>
                        <div className='class-month'>
                            <p className='month-title'>{moment(event.start).format('MMMM')}</p>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: 10}}>
                            <p className='month-date'>{moment(event.start).format('DD')}</p>
                            <p style={{color: '#a28f89', fontWeight: 'bold', margin: 0}}>{moment(event.start).format('dddd')}</p>
                        </div>
                    </div>

                    <div className='modal-description'>
                        <p style={{fontSize: 24}}>{event.className}</p>
                        <p style={{color: '#717171'}}>{event.venueName}</p>
                        <p style={{color: '#717171'}}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p>
                    </div>
                </div>

                <div className='divider' />

                <div>
                    <h2>Select Booking to Cancel</h2>
                    <ul>
                        {bookings.map((booking, index) => (
                            <li key={booking._id}>
                                Booking {index+1} made with {booking.paymentType}
                                <button
                                    className='om-btn'
                                    style={{
                                        padding: '2px 6px',
                                        marginLeft: '10px',
                                        height: 'auto',
                                        lineHeight: '1.2',
                                        minWidth: '60px',
                                    }}
                                    onClick={() => {handleCancellation(booking)}}
                                    disabled={cancelling}
                                >
                                    {cancelling ? "Cancelling" : "Cancel"}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <button
                        className='om-btn'
                        style={{
                            marginTop: '10px',
                        }}
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

